.image-wrapper{
    padding: 0;
    margin: 0;
    width: 100%;
    position: relative;
    img{
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
        font-family: "object-fit: contain";
    }
}

.caption{
    padding-top: 1.4rem;
}

.column .image-wrapper{
    margin-bottom: 1.4em;
}