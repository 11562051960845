/*
================================================================
LARGE SCREEN NAVIGATION
================================================================
*/

.button.menu-toggle-button{
    display: none;
}

.main-menu-list{
    border-top: none;
}

.site-title{
    opacity: 0;
}

.site-header{
    height: auto;
}

.js{
    .mask{
        display: none;
    }
    .main-menu-container{
        top:0;
        transform: translate(0,0);
        position: relative;
        overflow: hidden;
        height: 3rem;
        padding-left: var(--pageMargins);
        padding-right: var(--pageMargins);
        .main-menu-list{
            width: 100%;
            display: flex;
            justify-content: space-evenly;
        }
        li{
            display: inline-block;
            padding: 0;
            border-bottom: none;
        }
        a{
            padding: 0 1rem 0;
            display: block;
            line-height: 3rem;
        }
    }
}