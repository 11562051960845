$name: lg;  
$size: map-deep-get($breakpoints, "lg", "width"); 


@media only screen and (min-width: $size) {


/*
================================================================
VARIABLES
================================================================
*/

// :root {
//     --pageMargins: 30px;
//   }
  

/*
================================================================
LAYOUT
================================================================
*/


.main-content{
  padding-top: 200px;
}

/*
================================================================
TYPOGRAPHY
================================================================
*/

:root {
  --font-s: .875rem;
  --font-m: 1.125rem;
  --font-l: 1.125rem;
  --font-xl: 1.5rem;
  --font-article: 1.5rem;
}


/*
================================================================
GRID
================================================================
*/

.grid{
  column-gap: 45px;
  row-gap: 22.5px;
}

/*
================================================================
HOMEPAGE 
================================================================
*/

.recent-news-items{
  .news-item:nth-child(4){
      display: block;
  }
}

/*
================================================================
ARTICLE
================================================================
*/

.article-wrapper{
  max-width: 800px;
}


} /* End Media Query */