/*
================================================================
BUTTONS
================================================================
*/

.button{
    text-decoration: none;
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    cursor: pointer;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: inherit;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    text-align: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    outline: none;
    border-radius: 0;
    /* Remove excess padding and border in Firefox 4+ */
    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
}


.close-button{
    width: 18px;
    height: 18px;
    display: block;
    border-bottom: none!important;
    &:hover{
        path{
            stroke: red;
        }
    }
}