/*
================================================================
ACCESSIBILITY
================================================================
*/

.keyboard-focus{
    .bg-green *{
        *:focus{
            outline: none;
            outline: 3px solid white;
        }
    }
    
    *:focus{
        outline: none;
        outline: 3px solid black;
    }
}


a.skip-to-content{
    position: fixed;
    top: 0;
    left: 0;
    transform: translate(0, -100%);
    width: 100%;
    padding: 15px;
    text-align: center;
    display: block;
    transition: 200ms ease-in-out transform;
    color: white;
    z-index: 100000;
    text-decoration: none;
    &:focus{
        transition: 200ms ease-in-out transform;
        transform: translate(0,0);
        outline: none;
    }
}


/*
================================================================
HEADER
================================================================
*/

.site-header{
    position: fixed;
    top:0;
    left:0;
    height: 3.5em;
    width: 100%;
    z-index: 1000;
    transition: all 200ms ease-in-out;
    background: transparent;
}



.header-inner{
    position: relative;
    width: 100%;
    height: 100%;
}

.main-menu{
    width: 100%;
    height: 100%;
    background-color: white;
}

.site-title{
    position: fixed;
    left: 50%;
    top:0;
    transform: translate( -50%, 0);
    z-index: 100;
    width: 56px;
    height: 56px;
    padding: 10px;
    svg{
        width: 100%;
    }
    a{
        display: block;
    }
}


.button.menu-toggle-button{
    width: 3.5em; 
    height: 3.5em;
    position: fixed;    
    left: 0;
    top:0;
    display: block;
    padding: 17px;
    z-index: 99;
}

.keyboard-focus{
    .button.menu-toggle-button{
        &:focus{
            outline: none;
            outline: 3px solid black;
        }    
    }
}

.menu-toggle-button-inner{
    width:100%;
    height: 100%;
    position: relative;
    cursor: pointer;
}

.menu-bar{
    width: 26px;
    height: 2px;
    background-color: black;
    position: absolute;
    left: 0px;
    &:nth-child(1){
        top: 2px;
    }
    &:nth-child(2){
        top: 10px;
    }
    &:nth-child(3){
        top: 18px;
    }
}

.js{
    .mask{
        width: 100%;
        height: 56px;
        position: fixed;
        background-color: white;
        z-index: 98;
    }
    .main-menu-container{
        width:100vw;
        height: auto;
        background: white;
        position: fixed;
        transform: translate(0%, -150%);
        top:56px;
        transition: 200ms  transform ease-in-out;
        z-index: 97;
        max-height: calc(100vh - 56px);
        overflow: auto;
    }
}

.keyboard-focus{
    .button.menu-close{
        &:focus{
            outline: 3px solid white;
            background-color: transparent !important;
        }
    }
}

.main-menu-list{
    border-top: 1px solid black;
    font-size: inherit;
    text-transform: uppercase;
    color: white;
    text-align: center;
    li{
        padding: 20px;
        border-bottom: 1px solid black;
    }
    a, a:link, a:visited{
        text-decoration: none;
        padding: 5px;
    }
}

/*
================================================================
NO JS HEADER
================================================================
*/

.no-js{
    .site-header{
        position: static;
        height: auto;
    }
    .menu-toggle-button{
        display: none;
    }
    .site-title{
        position: absolute;
    }
    .main-menu-container{
        position: absolute;
        transform: translate(0, 0);
        display: block;
        top: 100px;
        width: 100%;
    }
    .main-menu-list{
        font-size: 1em;
        color: white;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px 0 10px;
        li{
            padding: 10px;
        }
        a, a:link, a:visited{
            text-decoration: none;
            padding: 5px;
        }
    }
    .menu-social{
        display: none;
    }
    .menu-close{
        display: none;
    }
}



.shrink{
    .site-header{
        background: transparent;
        transition: all 200ms ease-in-out;
    }
    .main-menu, .language-toggle-button{
        transform: translate(0,-100%);
        transition: all 200ms ease-in-out;
    }
}

.main-menu, .language-toggle-button{
    transition: all 200ms ease-in-out;
}

/*
================================================================
MENU TOGGLE
================================================================
*/

.main-menu-container.is-active{
    transform: translate(0,0);
    transition: 200ms  transform ease-in-out;
}


