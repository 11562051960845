/*
================================================================
FONTS
================================================================
*/

@font-face {
    font-family: 'sentinel';
    src: url("_webfonts/sl-light.eot");
    src: url("_webfonts/sl-light.eot") format("embedded-opentype"), url("_webfonts/sl-light.ttf") format("truetype");
    font-style: normal;
    font-weight: normal; 
  }
  
  @font-face {
    font-family: 'sentinel';
    src: url("_webfonts/sl-italic.eot");
    src: url("_webfonts/sl-italic.eot") format("embedded-opentype"), url("_webfonts/sl-italic.ttf") format("truetype");
    font-style: italic; 
    font-weight: normal; 
  }
  
   
  @font-face {
    font-family: 'sentinel';
    src: url("_webfonts/sb.eot");
    src: url("_webfonts/sb.eot") format("embedded-opentype"), url("_webfonts/sb.ttf") format("truetype");
    font-style: normal; 
    font-weight: bold; 
  }
  
  @font-face {
    font-family: 'orb-icons-v1';
    src: url('_webfonts/orb-icons-v1.eot?51436064');
    src: url('_webfonts/orb-icons-v1.eot?51436064#iefix') format('embedded-opentype'),
         url('_webfonts/orb-icons-v1.woff2?51436064') format('woff2'),
         url('_webfonts/orb-icons-v1.woff?51436064') format('woff'),
         url('_webfonts/orb-icons-v1.ttf?51436064') format('truetype'),
         url('_webfonts/orb-icons-v1.svg?51436064#orb-icons-v1') format('svg');
    font-weight: normal;
    font-style: normal;
  }

body{
    font-size: 16px;
    font-family:'sentinel', 'Helvetica Neue', 'Helvetica', 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
    line-height: 1.3;
    letter-spacing:1px;
}

strong, b{
    font-weight: bold;
}


/*
================================================================
LINKS
================================================================
*/


a:link, a:visited{
    text-decoration:none;
    color:#000;
    -webkit-transition: opacity .2s ease-in-out;
      -o-transition: opacity .2s ease-in-out;
         transition: opacity .2s ease-in-out;
  }
  
  a:hover, a:active{
    opacity:.5;
    -webkit-transition: opacity .2s ease-in-out;
      -o-transition: opacity .2s ease-in-out;
         transition: opacity .2s ease-in-out;
  }

/*
================================================================
HEADINGS
================================================================
*/

h1,h2,h3,h4,h5,h6{
    font-weight: normal;
}

/*
================================================================
PARAGRAPHS
================================================================
*/

p{
    padding-bottom:1.3em;
}



small{
    font-size:60%;
}

.small{
    padding-top:1em;
    font-size:.6em;
    line-height:1.5;
}

address{
    font-style: normal;
}

.caps{
    text-transform: uppercase;
}

/*
================================================================
FONT SIZING
================================================================
*/

:root {
  --font-s: .875rem;
  --font-m: 1.125rem;
  --font-l: 1.125rem;
  --font-xl: 1.375rem;
  --font-xxl: 2rem;
  --font-article: 1.25rem;
}

.font-s{
    font-size: var(--font-s);
}

.font-m{
    font-size:var(--font-m);
}

.font-l{
  font-size: var(--font-l);
}

.font-xl{
    font-size: var(--font-xl);
}

.font-xxl{
  font-size: var(--font-xxl);
}

.article-text{
  font-size: var(--font-article);
}
  
  /*  
  ================================================================
  RULES
  ================================================================  
  */
  
  hr{
    margin: 0;
    border : 0;
    height : 1px;
    background:rgba(0,0,0,.5);
    margin-bottom: 1.4rem
  }
  
  .dropdown-header hr{
    margin:0;
  }

  .long-dash{
    letter-spacing: -2px;
  }
  
  /*  
  ================================================================
  TITLES
  ================================================================  
  */
  
  h1, h2, h3, h4, h5, h6{
      font-size: 1em;
      font-weight: normal;
      padding-bottom: 1rem;
  }

  .page-title, h1{
    font-size:30px;
    margin:0;
    padding:0 0 15px;
  }
  
  .sub-header{
    font-size: 26px;
    margin:0;
    padding:0 0 15px;
  }
  
  .bold-title{
    font-weight: bold;
  }
  
  /*  
  ================================================================
  QUOTES
  ================================================================  
  */

  blockquote{
    //   font-style: italic;
      font-size: 1.125rem;
      display: block;
      margin: 0;
      padding: 0 2rem 2rem;
    //   text-align: center;
      footer{
          padding-top: 1.4rem;
          display: block;
          font-style: normal;
          font-weight: bold;
          font-size: .875rem;
          text-align: left;
      }
  }