/*
================================================================
FOOTER
================================================================
*/

.site-footer{
    padding-top: 3rem;
    font-size: .875rem;
    line-height: 1.5rem;
}