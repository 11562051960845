$name: md;  
$size: map-deep-get($breakpoints, "md", "width"); 

@media only screen and (min-width: $size) {


/*
================================================================
GRID
================================================================
*/  

.grid{
    column-gap: 30px;
}

/*
================================================================
TYPOGRAPHY
================================================================
*/

:root {
    --font-s: .875rem;
    --font-m: 1.125rem;
    --font-l: 1.125rem;
    --font-xl: 1.375rem;
    --font-xxl: 2.25rem;
    --font-article: 1.325rem;
}


/*
================================================================
LAYOUT
================================================================
*/


:root {
    --pageMargins: 45px;
  }
 
.t-pad{
    padding-top: 200px;
}

.b-pad{
    padding-bottom: 200px;
}


/*
================================================================
HOMEPAGE 
================================================================
*/

.recent-news-items{
    .news-item:nth-child(4){
        display: none;
    }
}

/*  
================================================================
PAGE TEMPLATE
================================================================  
*/

.image-item.portrait, .image-item.square{
width: 100%;
margin: 0 auto;
}

/*
================================================================
ARTICLE
================================================================
*/

.article-date{
    text-align: center;
}



.contact-grid{
    grid-template-columns: 150px 1fr;
    .long-dash{
        display: none;
    }
    dt, dd{
      padding-bottom: .7rem;
      margin: 0;
    }
  }

} /* End Media Query */
