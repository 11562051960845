$name: xl;  
$size: map-deep-get($breakpoints, "xl", "width"); 

@media only screen and (min-width: $size) {



/*
================================================================
TYPOGRAPHY
================================================================
*/

:root {
    --font-s: 1rem;
    --font-m: 1.25rem;
    --font-l: 1.25rem;
    --font-xl: 1.75rem;
}
  


/*
================================================================
GRID
================================================================
*/

.wrapper{
    max-width: 1600px;
    margin: 0 auto;
}

.grid{
    column-gap: 60px;
    row-gap: 30px;
}

.client-list ul{
    column-count: 2;
    column-gap: 2rem;
}


} /* End Media Query */
