/*
================================================================
MARGINS
================================================================
*/

:root {
  --pageMargins: 20px;
}


/*
================================================================
RESPONSIVE BREAKPOINTS
================================================================
*/

$breakpoints: (
  xs: ( width: 0px ),  
  sm: ( width: 500px ),
  md: ( width: 768px ),  
  lg: ( width: 1024px ),   
  xl: ( width: 1280px ),
  xxl:( width: 1500px),
  xxxl:( width: 1700px)
);  

$columns: 12;

/*
================================================================
DEEP FUNCTION
================================================================
*/


@function map-deep-get($map, $keys...) {
  @each $key in $keys {
      $map: map-get($map, $key);
  }
  @return $map;
}




/*
================================================================
GRID
================================================================
*/

.grid{
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 20px;
  row-gap: 40px;
}


@each $label, $map in $breakpoints {
  @media only screen and (min-width: map-get($map, width)) {
    
    /*
    ================================================================
    UTILITY CLASSES
    ================================================================
    */


    .#{$label}-visuallyhidden {
      position: absolute;
      overflow: hidden;
      clip: rect(0 0 0 0);
      height: 1px; width: 1px;
      margin: -1px; padding: 0; border: 0;
    }

    .#{$label}-hide {
      display: none;
    }

    .#{$label}-show {
      display: inline-block;
    }

    .#{$label}-full{
      grid-column: 1 / -1;
    }

    /*
    ================================================================
    GRID
    ================================================================
    */
  
    @for $x from 1 through $columns {
        .col-#{$label}-#{$x}{
          grid-column: span #{$x};
        }
      @for $i from 1 through $columns {
        .col-#{$label}-#{$x}-#{$i}{
          grid-column: #{$x} / span #{$i};
        }
      }
    }

  }
}


