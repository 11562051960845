/*
================================================================
Theme Name: Tom Merrell Studio
Author: Tom Merrell Studio
Author URI: http://www.tommerrell.com
================================================================
*/

// Setup:
// sass --watch sass:css --style compressed



// Base
@import "base/reset";

// Components
@import "components/variables";
@import "components/utilities";
@import "components/typography";
@import "components/forms";
@import "components/buttons";
@import "components/navigation";
@import "components/footer";
@import "components/images";
/*@import "components/cookies";*/
@import "components/grid";
@import "components/pages";
// @import "components/flickity";


// Responsive
// @import "responsive/mobile";
@import "responsive/sm";
@import "responsive/md";
@import "responsive/lg";
@import "responsive/xl";
@import "responsive/xxl";
@import "responsive/xxxl";

@media only screen and (min-width: 768px) { @import "responsive/large-navigation";}

// @media screen and (max-aspect-ratio: 6/7){ @import "responsive/portrait";}


/*@media only screen and (min-width: 479px) { @import "responsive/large-mobile";}
@media only screen and (min-width: 600px) { @import "responsive/mini-tablet";}
@media only screen and (min-width: 720px) { @import "responsive/tablet";}
@media only screen and (min-width: 950px) { @import "responsive/desktop";}
@media only screen and (min-width: 1200px) { @import "responsive/medium-desktop";}
@media only screen and (min-width: 1500px) and (min-height: 950px) { @import "responsive/large-desktop";}
@media screen and (max-aspect-ratio: 6/7){ @import "responsive/portrait";}
@media only screen and (max-height: 500px) {@import "responsive/short";}*/
