$name: sm;  
$size: map-deep-get($breakpoints, "sm", "width"); 


@media only screen and (min-width: $size) {

/*
================================================================
TYPOGRAPHY
================================================================
*/

:root {
    --font-s: 1rem;
    --font-m: 1.25rem;
    --font-l: 1.25rem;
    --font-xl: 1.5rem;
}

    

} /* End Media Query */
