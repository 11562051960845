input.email, button.submit{
    border:1px solid black;
    background:transparent;
    outline:0;
    padding:10px;
    font-family: 'sentinel', sans-serif;
    color: inherit;
    min-height: 39px;
}
::placeholder {
font-family: 'sentinel', sans-serif;
color: inherit;
opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: 'sentinel', sans-serif;
    color: inherit;
}
::-ms-input-placeholder { /* Microsoft Edge */
    font-family: 'sentinel', sans-serif;
    color: inherit;
}
  
input{
    font-size: 1em;
}

.sign-up-button-group{
    width: 100%;
    border: 1px solid black;
    position: relative;
    height: 39px;
    max-width: 200px;
    input.email{
        top:0;
        left:0;
        border: none;
        position: absolute;
    }
    button.submit{
        position: absolute;
        right:0;
        width: 38px;
        padding: 7px;
        border: none;
    }
}
  
.landing-label-wrapper{
    font-size: .75em;
    margin-bottom: 1em;
}