$name: xxl;  
$size: map-deep-get($breakpoints, "xxl", "width"); 

@media only screen and (min-width: $size) {
 
/*
================================================================
TYPOGRAPHY
================================================================
*/

:root {
    --font-s: 1.125rem;
    --font-m: 1.375rem;
    --font-l: 1.625rem;
    --font-xl: 2.25rem;
}




} /* End Media Query */
