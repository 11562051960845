

/*
================================================================
LAZY LOADING
================================================================
*/

.lazy, .lazyload, .lazyloading{
    opacity: 0;
}

.loaded, .lazyloaded{
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}


/*  
================================================================
PAGE LAYOUT
================================================================  
*/

html, body{
    width:100%;
    overflow-x: hidden;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
  
  .site-container{
       flex: 1;
  }
  
  .home{
      overflow:hidden;
  }
  
  .main-content{
      padding:150px 0px 8em;
  }
  
  .page-content{
    padding-bottom:1em;
  }
  

  .page-margins{
      padding-left: var(--pageMargins);
      padding-right: var(--pageMargins);
  }

  .t-pad-1{
      padding-top: var(--pageMargins);
  }
  
  .b-pad-1{
        padding-bottom: var(--pageMargins);
}

  /*  
  ================================================================
  HEADER
  ================================================================  
  */
  
  .header-logo{
      display:none;
  }
  
  
  h1.title{
      margin:0;
      font-size:1.125em;
      text-transform:uppercase;
      text-align:center;
      width:100%;
      border-bottom:1px solid black;
      padding:0 0 27px;
      line-height:1;
      z-index:2001;
      font-family: 'sb', arial ,sans-serif;
  }
  
  .desktop-menu{
    display: none;
  }
  
  /*  
  ================================================================
  FOOTER
  ================================================================  
  */
  
  .footer-logo{
      width:75px;height:75px;
      margin:10px auto 20px;
  /*    background-image:url('img/orbis-logo.png');
  */    display:block;
  }
  
  .site-footer address, .site-footer form{
    display: block;
    padding-bottom:10px;
  }
  
  .site-footer .button-wrapper{
    padding-top: 10px;
  }
  
  .footer-nav{
      padding-top: 10px;
      padding-bottom: 20px;
  }
   
  address p{
    padding: 0;
  }
  
  span.email{
    padding-top: 0;
    display: block;
  }
  
  footer .col{
    padding-bottom: 1em;
  }
  
  
  /*  
  ================================================================
  COOKIE BAR
  ================================================================  
  */
  
  
  #cookie-bar{
    p{
        padding-bottom:15px;
    }
  }
  
  
  /*  
  ================================================================
  LANDING PAGE v2
  ================================================================  
  */
  
  .landing-page-wrapper{
    position: relative;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
  } 
  
  .landing-page-slideshow, .overlay-logo{
    position:absolute;
    // top:58px;
    top:0;
    left:0;
    right:0;
    bottom:0px;
    z-index: 100;
  }
  
  .overlay-logo{
    background-color: rgba(0,0,0,.2);
    padding:0 15px 0;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    img{
      width:60vw;
      max-width:500px;
      max-height: 500px;
      object-fit: contain;
      font-family: 'object-fit: contain;';
    }
  }
  
  
  .landing-slide{
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width:100%;
      height:100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }
  
  
  .recent-news{
    .recent-news-header{
      padding-bottom: 30px;
    }
  }
  
  /*  
  ================================================================
  Mailing List Sign Up (landing page)
  ================================================================  
  */
  
  .landing-page-signup{
    font-size:.875em;
    position: absolute;
    bottom:0;
    right:0;
    padding-top:15px;
    padding-bottom: 15px;
    width:100%;
  }
  
  
  /*  
  ================================================================
  Mailing List Sign Up (landing page) v2
  ================================================================  
  */
  
  .landing-page-lockup-form{
    margin-top: 1em;
    color:white;
    font-weight: normal;
    input.email, button.submit{
      border:none;
      background:transparent;
    }
    .sign-up-button-group{
        border: 1px solid white;
        margin: 0 auto;
        max-width: 300px;
    }
    .arrow{
        stroke: #fff;
    }
  }
  
  .label-wrapper{
    margin-bottom: 1em;
  }
  

  /*  
  ================================================================
  PAGE TEMPLATE
  ================================================================  
  */

  .image-item.portrait, .image-item.square{
    width: 60%;
    margin: 0 auto;
  }

  /*  
  ================================================================
  BLOCKS
  ================================================================  
  */

  .smallImage{
    padding-bottom: 3rem;
  }
  
  
  /*  
  ================================================================
  PRESS
  ================================================================  
  */

  .press-item{
    border-bottom: 1px solid rgba(0,0,0,.3);
    // padding-top: 1rem;
    padding-bottom: 22.5px;
    &:last-child{
      border-bottom: none;
    }
  }

  .article-subtitle{
    padding-bottom: 1rem;
  }
    
  .links{
    padding: 0 0 ;
  }
  .links a{
    display: block;
  }
  
  
  
  /*  
  ================================================================
  IMAGES
  ================================================================  
  */
  
  p img, .image-area img, img{
      max-width:100%;
  }
  
  .image-caption{
    padding-top: 1em;
    font-style: italic;
    text-align: center;
  }
  
  .slideshow-wrapper .image-caption{
   padding-top: 2.5em;
  }
  
  .image-slide{
    padding:0 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-slide img{
    max-height: 500px;
    margin: 0 auto;
  }
  
  
  /*  
  ================================================================
  SLIDESHOW
  ================================================================  
  */
  
  .slideshow-wrapper{
    //position: relative;
    width:100%;
   // padding-bottom:100%;
  }
  
  .slideshow{
   // position: absolute;
    top:0;left:0;
    width:100%;
   // height:100%;
  }
  
  .slide{
    width:100%;
    //height:100%;
    //display: flex;
    //justify-content: center;
    //align-items: center;
    text-align: center;
  }
  
  .slide img{
    //max-width: 100%;
    margin:0 auto;
    max-height: 500px;
  }
  

     
  /*  
  ================================================================
  NEWS PAGE
  ================================================================  
  */

  .news-item{
    padding-bottom: 3em;
  }
  
  
  .news-image img{
      width:100%;
      color:transparent;
  }
  
  .title-group{
      padding:.5em 0 .25em;
  }
  
  .post-title{
      font-size:1.25em;
      padding-bottom:0;
  }
  
  a.read-more{
      text-decoration:underline;
      display:inline-block;
  }
      
  .dagger{
      font-size:1.25em;
      padding: 1.5em 0 4.5em;
      width:100%;
      text-align:center;
  }
  
  .related img{
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    mix-blend-mode: multiply;
  }
  

  .title-group{
    .article-category{
      text-transform: capitalize;
      font-weight: bold;
    }
    .article-category, h3{
      display: inline;
    }
  }


    
  /*  
  ================================================================
  ARTICLE
  ================================================================  
  */

  .article-date{
    text-align: right;
  }

  .article-title{
    text-align: center;
    padding: 2em 0 2em;
    max-width: 30ch;
    margin: 0 auto;
  }

  .article-image{
    padding-bottom: 2.8em;
  }

  .article-image.portrait{
    width: 60%;
    margin: 0 auto;
  }

  .article-wrapper{
    max-width: 700px;
    margin: 0 auto;
  }

  .article-content{
    .article-image, .article-text{
      padding-bottom: 3rem;
    }
  }
  
  /*  
  ================================================================
  CONTACT PAGE
  ================================================================  
  */
  
  
 dl{
   padding: 0;
   margin: 0;
 }

 .contact-grid{
   display: grid;
   grid-template-columns: 1fr;
   dt, dd{
     padding-bottom: .7rem;
   }
 }

 
  /*  
  ================================================================
  TEXT PAGE
  ================================================================  
  */

  .default-text{
    h3{
      padding-top: 2em;
      font-weight: bold;
      text-transform: uppercase;
    }
    ul{
      list-style-type: disc;
      padding-bottom: 1.4em;
      li{
        margin-left: 2em;
      }
    }
    a, a:link, a:visited{
      text-decoration: underline;
    }
  }